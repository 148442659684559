<template>
  <router-view
    v-if="showcontent" />
</template>

<script>

export default {
  watch: {
    '$route.fullPath' (val) {
      this.resetpath(val)
    }
  },
  data () {
    return {
      showcontent: false
    }
  },
  mounted () {
    if (this.$route.fullPath !== '/index' &&
      this.$route.fullPath === '/h5-index'
    ) {
      this.showcontent = true
    }
    this.resetpath(this.$route.fullPath)
  },
  methods: {
    resetpath () {
      console.log(this.$route.fullPath)
      if (this.$route.fullPath === '/index') {
        if ((screen.width || window.innerWidth) < 470) {
          this.$router.replace('/h5-index')
          this.showcontent = true
        } else {
          this.showcontent = true
        }
      }
      if (this.$route.fullPath === '/h5-index') {
        if ((screen.width || window.innerWidth) >= 470) {
          this.$router.replace('/index')
          this.showcontent = true
        } else {
          this.showcontent = true
        }
      }
    }
  }
}
</script>

<style lang="scss">
.pc-laytout {
  min-width: 1920px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
}
img {
  display: block;
}
.outer {
  width: 100%;
  .inner {
    width: 1920px;
    margin: 0 auto;
    position: relative;
  }
  .innerh5 {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;
  }
}
.p1320 {
  width: 1320px !important;
}
.p1642 {
  width: 1642px !important;
}
.p1340 {
  width: 1340px !important;
}
.p1520 {
  width: 1520px !important;
}
.p1834 {
  width: 1834px !important;
}
.p1620 {
  width: 1620px !important;
}
.p1680 {
  width: 1680px !important;
}
.p1740 {
  width: 1740px !important;
}
.p1800 {
  width: 1800px !important;
}
.p1876 {
  width: 1876px !important;
}
.flc {
  display: flex;
  align-items: center;
  justify-content: center;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
