import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/right-supervise',
    name: 'right-supervise',
    component: () => import('../views/right-supervise.vue')
  },
  {
    path: '/politic-ecology',
    name: 'politic-ecology',
    component: () => import('../views/politic-ecology.vue')
  },
  {
    path: '/politic-supervise',
    name: 'politic-supervise',
    component: () => import('../views/politic-supervise.vue')
  },
  {
    path: '/ecology-association',
    name: 'ecology-association',
    component: () => import('../views/ecology-association.vue')
  },
  {
    path: '/h5-index',
    name: 'h5-index',
    component: () => import('../views/h5-index.vue')
  },
  {
    path: '/h5-right-supervise',
    name: 'h5-right-supervise',
    component: () => import('../views/h5-right-supervise.vue')
  },
  {
    path: '/h5-politic-ecology',
    name: 'h5-politic-ecology',
    component: () => import('../views/h5-politic-ecology.vue')
  },
  {
    path: '/h5-politic-supervise',
    name: 'h5-politic-supervise',
    component: () => import('../views/h5-politic-supervise.vue')
  },
  {
    path: '/h5-ecology-association',
    name: 'h5-ecology-association',
    component: () => import('../views/h5-ecology-association.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
